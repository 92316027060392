// @import "~antd/dist/antd.css";
@import "font/iconsmind-s/css/iconsminds.css";
@import "react-perfect-scrollbar/dist/css/styles.css";
@import "font/simple-line-icons/css/simple-line-icons.css";
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.advanced-filters-button-container {
  display: flex;
  justify-content: end;
}

.advancefilter {
  .ant-form-item {
    flex-direction: inherit !important;
  }
  .ant-form-item-label-left {
    display: inline-block;
    width: 30%;
  }
  .ant-form-item-control-wrapper {
    display: inline-block;
    width: 70%;
  }
}

.advancefilter-label {
  align-self: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container {
  display: flex;
  justify-content: center;
  padding: 10px;
  height: 100vh;
  .form {
    width: 400px;
    margin: auto;
    .login-form-button {
      width: 100%;
    }
  }
}
