@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,400;1,500;1,600;1,700&display=swap");

html {
  width: 100%;
  height: 100%;
  background: #fff;
}
html,
body {
  overscroll-behavior: none; /* Prevent scroll chaining */
}
body {
  font-family: "Poppins", sans-serif !important;
  color: #000 !important;
  background: #fff;
  padding: 0;
  margin: 0;
}
.ant-layout {
  background: #fff;
}
.ant-layout-header {
  background: #fff;
  height: 56px;
  line-height: 56px;
}
.ant-layout .ant-layout-sider {
  position: relative;
  min-width: 0;
  background: #fff;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.3;
  font-weight: 600;
}

h1 {
  font-size: 1.75rem;
  padding-bottom: 10px;
  display: inline-block;
}

@media (max-width: 767px) {
  h1 {
    font-size: 1.3rem;
    padding-top: 0.25rem;
    margin-bottom: 0;
  }
}

h2 {
  font-size: 1.4rem;
}

@media (max-width: 767px) {
  h2 {
    font-size: 1.1rem;
  }
}

h3 {
  font-size: 1.3rem;
}

@media (max-width: 767px) {
  h3 {
    font-size: 1rem;
  }
}

h3 {
  font-size: 1.2rem;
}

@media (max-width: 767px) {
  h3 {
    font-size: 1rem;
  }
}

h4 {
  font-size: 1.15rem;
}

@media (max-width: 767px) {
  h4 {
    font-size: 0.9rem;
  }
}

h5 {
  font-size: 1.1rem;
}

@media (max-width: 767px) {
  h5 {
    font-size: 0.9rem;
  }
}

h6 {
  font-size: 0.9rem;
}

@media (max-width: 767px) {
  h6 {
    font-size: 0.85rem;
  }
}
.truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.separator {
  border-bottom: 1px solid #eee;
}
a {
  color: #1677ff;
  transition: color 0.2s;
  outline: initial !important;
}

a:active,
a:hover {
  text-decoration: initial;
  color: #0292c7;
}
p {
  font-size: 0.9rem;
  line-height: 1.3rem;
  font-family: "Poppins", sans-serif;
}
.font-weight-semibold {
  font-weight: 600;
}
.text-small {
  font-size: 0.76rem !important;
  line-height: 0.9rem !important;
}
.text-primary,
.text-theme-1 {
  color: #0292c7 !important;
}
.text-right {
  text-align: right;
}
.h-100 {
  height: 100%;
}
.right-width {
  width: 330px;
  left: 0;
  text-align: left;
  padding: 0;
  border-right: solid 1px #eee;
  border-bottom: solid 1px #eee;
  border-top: solid 1px #eee;
  height: 56px;
  position: fixed;
  z-index: 999;
  background: #fff;
}
.normal-logo {
  width: 180px;
  padding-top: 7px;
  padding-left: 15px;
}
.right-width i {
  font-size: 20px;
  color: #3a3a3a;
}
.app-menu {
  z-index: 1;
  width: 330px;
  height: 100%;
  float: right;
  transition: transform 0.3s;
  position: fixed;
  left: 0;
  top: 56px;
  border-right: solid 1px #eee;
  background: #fff;
  height: calc(100% - 100px);
}
.app-menu .ant-card-small > .ant-card-body {
  padding: 0.6rem;
}
.appoin-details {
  /* padding-right: 25px; */
  position: relative;
}
.appoin-right-icon {
  /* position: absolute;
  right: -4px;
  top: 33%; */
  font-size: 23px;
}
.appoin-right-icon a {
  color: #0292c7 !important;
}
.projects-new .text-muted {
  color: #444 !important;
}

.highLightCard {
  background-color: #bcb8b8;
}

.app-menu .ant-card:not(.ant-card-bordered) {
  box-shadow: none;
  background: #fff;
  border-bottom: solid 1px #eee;
  border-radius: 0;
}
.app-menu .ant-tabs > .ant-tabs-nav,
.app-menu .ant-tabs > div > .ant-tabs-nav {
  position: fixed;
  display: flex;
  flex: none;
  align-items: center;
  bottom: 0px;
  left: 0;
  width: 330px;
  z-index: 999;
  background: #fff;
  border-right: solid 1px #eee;
}

.ant-tag {
  border-radius: 10px;
}
.ant-tag-success {
  color: #fff;
  background: #00b050;
  border-color: #b7eb8f;
}
.ant-tag-processing {
  color: #fff;
  background: #0292c7;
  border-color: #91caff;
}
.ant-tag-error {
  color: #fff;
  background: #c00000;
  border-color: #ffccc7;
}
.ant-tag-warning {
  color: #fff;
  background: #f16e16;
  border-color: #ffe58f;
}
.ant-tag-default {
  background-color: #808080;
  color: #fff;
}
.ant-tag-blue {
  color: #fff;
  background: #0292c7;
  border-color: #0292c7;
}
.app-menu .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
  color: #fff !important;
  background-color: #0292c7;
}
.app-menu .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
  text-shadow: 0 0 0.25px currentcolor;
}
.app-menu .ant-tabs-card.ant-tabs-bottom > .ant-tabs-nav .ant-tabs-tab {
  border-radius: 4px;
  margin-right: 8px;
}
.app-menu .ant-tabs-nav-wrap {
  padding-top: 10px;
  padding-bottom: 10px;
}
.app-menu .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab {
  padding: 0.3rem 0.8rem;
  font-size: 0.8rem;
}
.app-menu .ps--active-x > .ps__rail-x,
.app-menu .ps--active-y > .ps__rail-y,
.menu .ps--active-x > .ps__rail-x,
.menu .ps--active-y > .ps__rail-y {
  display: none;
}
.menu {
  position: fixed;
  top: 0px;
  right: 0;
  bottom: 0;
  padding-top: 0px;
  z-index: 5;
  height: calc(100% - 0px);
  transition: border-radius 0.3s;
  width: 120px;
  border-left: solid 1px #eee;
  border-top: solid 1px #eee;
  background: #fff;
}
.menu i {
  font-size: 22px;
  line-height: 32px;
}

.ant-menu-title-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;

  color: #3a3a3a;
  transition: color 0.3s;
  transition: background 0.3s;
  text-align: center;
}
.ant-menu-title-content span {
  text-align: center;
  padding: 0 10px;
  line-height: 18px;
}
.ant-menu-inline .ant-menu-item {
  height: 70px;
  border-bottom: 1px solid #f3f3f3;
  padding: 0;
  margin: 0;
  border-radius: 0;
}
.ant-menu-light.ant-menu-inline .ant-menu-item {
  position: relative;
  width: 100%;
  padding-left: 0 !important;
}
.ant-menu-light:not(.ant-menu-horizontal)
  .ant-menu-item:not(.ant-menu-item-selected):hover {
  color: #0292c7;
  background-color: transparent;
}

.techapp {
  width: 100%;
  padding: 0 15px;
}
.alltab-main {
  position: relative;
  left: 330px;
  width: calc(100% - 330px);
  top: 0;
}

.menu-large {
  padding: 16px 0;
  background: #fff;
  border-bottom: solid 1px #eee;
  border-top: solid 1px #eee;
}
.text-center {
  text-align: center !important;
}
.summary label {
  color: #0292c7 !important;
  font-weight: 600;
}
.mb-1 {
  margin-bottom: 0.25rem !important;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.pb-2 {
  padding-bottom: 0.5rem !important;
}
.pt-3,
.py-3 {
  padding-top: 1rem !important;
}
.inner-scroll2 {
  min-height: 45px;
}
.summary-main .ant-carousel .slick-dots li.slick-active button {
  background: #0292c7;
  opacity: 1;
}
.summary-main .ant-carousel .slick-dots li button {
  background: #999;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.summary-main .ant-carousel .slick-dots-bottom {
  bottom: -15px;
}
.summary-main .ant-carousel .slick-dots li {
  width: 24px;
}
.summary-main .ant-carousel .slick-initialized .slick-slide {
  display: block;
  position: relative;
}
.site-edit-button {
  position: absolute;
  top: 8px;
  right: 0;
  z-index: 999;
}
.site-edit-button i {
  font-size: 20px;
}
.ant-modal-root .ant-modal-mask {
  background-color: rgba(0, 0, 0, 0.25);
}
.ant-form .ant-col-md-24.ant-form-item-label {
  padding: 0;
}
.ant-form-item {
  margin-bottom: 10px;
}
.ant-btn {
  border-radius: 50px;
  height: 39px;
  padding: 4px 20px;
}
.ant-btn-primary {
  background-color: #0292c7;
  border-color: #0292c7;
  color: #fff;
}
h5.ant-typography {
  margin-bottom: 0;
  font-size: 14px;
}
/* .ant-btn-primary:hover {
  color: #fff;
  background-color: #28b1e4;
  border-color: #28b1e4;
} */
.appoin-plus {
  position: absolute;
  bottom: -37px;
  right: 0;
  z-index: 9999;
}
.appoin-plus i {
  color: #0292c7;
  font-size: 20px;
}
.ant-modal .ant-modal-body {
  overflow-y: auto;
  max-height: calc(100vh - 200px);
}
.ant-modal .ant-modal-header {
  border-bottom: solid 1px #eee;
  padding-bottom: 10px;
}
.ant-card .ant-card-body {
  padding: 18px;
  border-radius: 0 0 8px 8px;
}
.fixed-top {
  position: fixed;
  left: 330px;
  right: 120px;
  background: #fff;
  z-index: 999;
  height: 56px;
}
.middle-top-icons {
  position: absolute;
  right: 0;
  top: 3px;
}
.middle-top-icons a i,
.middle-top-icons i {
  color: #0292c7 !important;
  font-size: 24px;
}
.equipment {
  padding: 15px 15px;
  padding-top: 71px;
  background-color: #fff;
}
.equipment .ant-table .ant-table-thead .ant-table-cell {
  display: none;
}
.color-2new,
.color-2new label {
  color: #02c79a !important;
}

.color-3new,
.color-3new label {
  color: #022fc7 !important;
}
.color-2new .no-color,
.color-3new .no-color {
  color: rgba(0, 0, 0, 0.88) !important;
}
.theme-color-new {
  color: #0292c7;
}
.grey-bg {
  background-color: #fafafa !important;
}
.p-1 {
  padding: 0.5rem !important;
}
.p-2 {
  padding: 1rem !important;
}
.mb-0 {
  margin-bottom: 0 !important;
}
.pt-0 {
  padding-top: 0;
}
.pt-0 .ant-card-body {
  padding-top: 0;
}
.ant-spin {
  max-height: inherit !important;
}
.tab-top-details {
  margin-top: -12px;
  position: relative;
}
.tab-detail-top-icons {
  position: absolute;
  top: 0px;
  right: 0px;
}
.tab-detail-top-icons i {
  color: #0292c7 !important;
  font-size: 20px;
}
.equip-detail .ant-form-item .ant-form-item-label {
  text-align: left;
  white-space: inherit;
}
.equip-detail .ant-form-item .ant-form-item-label > label::after {
  content: "";
}
.equip-detail .ant-form-item {
  margin-bottom: 5px;
  border-bottom: solid 1px #eee;
  padding-bottom: 5px;
}
.equip-detail .ant-divider-horizontal {
  margin: 0 0 5px 0;
}
.text-muted {
  color: #909090 !important;
}
.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #0292c7;
}
.kolostatmain {
  position: relative;
  width: calc(100% - 120px);
}
.labour-part {
  border-bottom: solid 1px #eee;
  margin-bottom: 7px;
  padding-bottom: 7px;
  position: relative;
}
.middle-top-icons .ant-btn {
  padding: 4px 10px;
}
.form-new-kolotech .ant-form-item {
  margin-bottom: 8px;
  border-bottom: solid 1px #eee;
  padding-bottom: 8px;
}
.form-border-none.ant-form-item {
  border-bottom: none !important;
}
.form-new-kolotech .ant-form-item .ant-form-item-label {
  text-align: left;
  white-space: inherit;
}
.form-new-kolotech .ant-form-item .ant-form-item-label > label::after {
  content: "";
}
.labour-delete {
  position: absolute;
  right: -7px;
  top: 0px;
}
.labour-part a:hover {
  color: rgba(0, 0, 0, 0.88);
}
.labour-delete a {
  background-color: #ff4d4f;
  padding: 4px 10px;
  color: #fff;
  border-radius: 3px;
}
.labour-delete i {
  font-size: 14px;
}
.labour-delete1 {
  position: absolute;
  right: 10px;
  top: 7px;
}
.width-new {
  width: 41px;
  display: inline-block;
}
.width-new1 {
  width: 50%;
  display: inline-block;
  vertical-align: top;
}
.checkbox-task {
  width: 30px;
  display: inline-block;
  vertical-align: top;
  padding-top: 0px;
  position: absolute;
  right: 0;
}
.task-body .ant-card-body {
  padding: 10px;
}
.checkall-btn .ant-btn {
  border-radius: 5px;
  padding: 4px 13px;
}
.inner-scroll {
  max-height: 239px;
}
.inner-scroll ul {
  padding: 0 15px;
}
.menu .ant-menu-light .ant-menu-item-selected {
  background-color: transparent;
}
.menu .ant-menu-light .ant-menu-item-selected:after {
  content: " ";
  background: #0292c7;
  color: #fff;
  border-radius: 10px;
  position: absolute;
  width: 6px;
  height: 40px;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.worked-hours span {
  line-height: 12px;
}
.worked-hours.ant-flex-gap-middle {
  gap: 7px !important;
}
.border-bottom {
  border-bottom: solid 1px #eee;
  padding-bottom: 10px;
}
.text-danger {
  color: #dc3545 !important;
}
.text-success {
  color: #28a745 !important;
}
.app-menu-button {
  position: absolute;
  right: -30px;
  background: #fff;
  top: 45px;
  padding: 12px 8px 12px 5px;
  border-radius: 0;
  color: #8f8f8f;
  border: solid 1px #eee;
  border-left: none;
  font-size: 15px;
  line-height: 0;
}
.d-inline-block {
  display: inline-block !important;
}
.app-menu.shown {
  transform: translateX(0);
}
.logo-single {
  width: 180px;
  height: 35px;
  margin-bottom: 40px;
}
@media (max-width: 575px) {
  .logo-single {
    margin-bottom: 20px;
  }
}
@media (min-width: 768px) {
  .d-mdnew-none {
    display: none !important;
  }
  .alltab-main .ant-spin-nested-loading > div > .ant-spin-lg .ant-spin-dot {
    margin: -16px -76px;
  }
}
@media (max-width: 1199px) {
  .width-new1 {
    width: calc(100% - 81px);
    display: inline-block;
    vertical-align: top;
  }
}
@media (max-width: 1100px) {
  .summary label,
  .summary .ant-typography,
  .ant-form-item .ant-form-item-label > label {
    font-size: 13px;
  }
}
@media (max-width: 767px) {
  .responsive-layout-custom {
    width: 767px;
    overflow: scroll;
  }
  .kolostatmain {
    width: 100%;
  }
  .sm-mt-1 {
    margin-top: 0.5rem;
  }
  .menu {
    right: 0;
    z-index: 9999;
  }
  .ant-layout .ant-layout-sider {
    display: none;
  }
  .fg.shown .ant-layout-sider {
    display: block;
  }
  /* .fg.shown .ant-layout-sider.vamshi {
    display: none;
  } */
  .ant-layout .ant-layout-sider.shown {
    display: block;
    flex: 1 !important;
    max-width: inherit !important;
    min-width: inherit !important;
  }
  .app-menu {
    transform: translateX(-330px);
  }
  /* .app-menu {
    display: none;
  } */
  .app-menu .ant-tabs > .ant-tabs-nav,
  .app-menu .ant-tabs > div > .ant-tabs-nav {
    bottom: -34px;
  }
  .app-menu .app-menu-button {
    top: 5px;
  }
  .alltab-main {
    position: relative;
    left: 0;
    width: 100%;
  }
  .summary-main {
    top: 56px;
    padding-bottom: 56px;
  }
  .right-width {
    width: 100%;
  }
  .summary-main .ant-carousel .slick-dots-bottom {
    bottom: -15px;
  }
  .sm-dnone {
    display: none;
  }
  .fixed-top {
    position: relative;
    left: inherit;
    right: inherit;
    z-index: inherit;
    top: 56px;
  }
  .fixed-top h4 {
    text-align: left !important;
    padding-left: 38px;
  }
}
@media (max-width: 991px) {
  .d-md-none {
    display: none;
  }
}
@media (max-width: 575px) {
  .ant-form-item .ant-form-item-label {
    padding: 0;
  }
}
.app-bottom {
  cursor: pointer;
  padding-top: 2px;
}
.pro-table .ant-typography {
  margin-bottom: 0;
}
.app-bottom .ant-typography {
  margin-bottom: 0;
}
.card-active {
  background: #ededed !important;
}
.ant-dropdown-menu {
  max-height: 250px;
  overflow: auto;
}
.history-box {
  min-height: 60px;
}
.historydetails-main .ant-divider-horizontal {
  margin: 10px 0;
}
.visithistory-left .app-bottom .ant-typography {
  margin-bottom: 5px;
}

.ant-spin-nested-loading > div > .ant-spin {
  z-index: 9999;
}

.taskslistnew .ant-checkbox {
  /* display: inline-block;  */
  padding: 0.5rem !important;
}
.task-inner-record {
  display: flex;
  padding: 0.5rem !important;
  border-left: solid 1px #eee;
}
.checkbox-left {
  width: 40px;
  display: inline-flex;
}
.checkbox-right {
  width: calc(100% - 40px);
  display: inline-flex;
}
.task-record {
  display: "flex";
  margin-bottom: 8px;
  width: 100%;
  background: #fff;
  border: solid 1px #eee;
}
.taskslistnew .ant-checkbox-wrapper {
  display: inline-flex;
}
.post-icon {
  background: #fcfcfc;
  height: 46px;
  border-bottom: solid 1px #eee;
}
.post-icon i {
  font-size: 1.1rem;
  vertical-align: middle;
  color: #0292c7;
}
.mr-7 {
  margin-right: 7px;
}
.mr-20 {
  margin-right: 20px;
}
.additionalwork .ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 10px;
  padding-bottom: 30px;
}
.additionalworkmodal .ant-form-item-control-input {
  margin-bottom: 30px;
}
.menu .ant-menu-item-disabled i,
.ant-menu-item-disabled .ant-menu-title-content,
.middle-top-icons .ant-btn:disabled i {
  color: rgba(0, 0, 0, 0.25) !important;
}
.stagenew .ant-form-item {
  margin-bottom: 30px;
}
.additionalwork-delete {
  background-color: #ff4d4f;
  padding: 4px 10px;
  color: #fff;
  border-radius: 3px;
  text-align: center;
}
.additionalwork-delete a {
  color: #fff;
}
.additionalwork-delete a:hover {
  color: #333;
}
.additionalwork-delete i {
  font-size: 14px;
}
.ant-btn-dangerous {
  border-color: #ff4d4f;
}
.drawing-component {
  overflow: hidden;
}
.height100vh {
  height: calc(100vh - 56px);
}
.ant-spin-nested-loading {
  height: 100%;
}
.newsearchfilter {
  padding-top: 10px;
}
.ant-form-item .ant-form-item-control-input-content {
  word-wrap: break-word;
}
.preview-modal {
  top: 37px !important;
}
.more-new {
  color: #0292c7;
  display: block;
  top: -15px;
  position: relative;
}
.ant-notification
  .ant-notification-notice-wrapper
  .ant-notification-notice-with-icon
  .ant-notification-notice-message {
  font-size: 14px;
  margin-bottom: 0;
}
.ant-notification .ant-notification-notice-wrapper .ant-notification-notice {
  padding: 10px;
}
.ant-notification
  .ant-notification-notice-wrapper
  .ant-notification-notice-icon {
  font-size: 20px;
}
.font-20 {
  font-size: 20px;
}
.settings-dropdown
  .ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-item:hover {
  background-color: initial;
}
.filter-icon-position {
  /* top: 8px; */
  position: relative;
}
.visit-filter-icon .filter-icon-position {
  top: 4px;
  /* position: relative; */
}
.ant-list * {
  overflow-x: hidden;
}
.ant-empty .ant-empty-image {
  overflow: hidden;
}

.settings-dropdown .ant-dropdown-menu-item:hover {
  background-color: transparent !important;
}
.settings-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  cursor: auto !important;
}
.settings-dropdown a:hover {
  color: #0292c7 !important;
}
.height94 {
  height: calc(100% - 45px);
}
.appoint-btn {
  position: relative;
  align-items: center;
  width: 330px;
  z-index: 999;
  background: #fff;
  border-right: solid 1px #eee;
  border-top: solid 1px #eee;
  padding: 1px 0;
}
.appoint-btn i {
  font-size: 18px;
}
.appoint-btn .ant-btn {
  border-radius: 5px;
  margin: 5px;
  height: 30px;
  padding: 4px 10px;
}
.appoint-btn .appoint-btn-active {
  background-color: #0292c7 !important;
  border-color: #0292c7 !important;
  color: #fff !important;
}
.visit-filter-icon {
  height: 39px;
  padding: 5px 20px;
}
.visit-filter-icon i {
  color: #0292c7 !important;
  font-size: 1.1rem;
  vertical-align: middle;
}
.middle-top-icons .ant-btn.filter-btn-new {
  position: relative;
  top: -4px;
  margin-right: 10px;
  padding: 0 10px;
}
.ant-carousel .slick-dots {
  z-index: inherit;
}
@media (max-width: 1100px) {
  .fixed-top h4 {
    text-align: left !important;
    padding-left: 38px;
  }
}

.scroll-wrapper {
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
}
.last-new-menu {
  white-space: inherit !important;
}
.checkbox-new .ant-checkbox-disabled .ant-checkbox-inner {
  background: #00b050;
  border-color: #00b050;
}
.checkbox-new .ant-checkbox-disabled .ant-checkbox-inner:after {
  border-color: #fff !important;
}
.ql-editor {
  min-height: 200px;
}
.action-disable-error-msg p {
  margin: 0;
  color: #ea0707;
  text-align: center;
  font-weight: 600;
}
/* Start To remove :increment and :decrement from input number in parts */
.parts-increment-decrement .ant-input-number .ant-input-number-handler-wrap,
.parts-increment-decrement
  .ant-input-number
  .ant-input-number-handler-wrap
  .ant-input-number-handler {
  display: none;
}
/* End To remove :increment and :decrement from input number in parts */
.ant-picker-dropdown
  .ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell
  .ant-picker-time-panel-cell-inner {
  height: 48px;
  line-height: 48px;
  width: 58px;
  text-align: center;
  padding: 0;
}
.ant-picker-dropdown .ant-picker-time-panel-column {
  width: 76px;
}
@media (max-width: 639px) {
  .height94 {
    height: calc(100% - 78px);
  }
}
.ant-list {
  height: 100%;
}
.ant-layout .ant-layout-sider.test {
  background: transparent;
}
